<template>
	<div :style="forgetBg">
		<label class="title-text">{{$t('login.forgetPwd')}}</label>
		
		<div class="column-center-content main-content-no-nav">
			<div class="info-content">
				<v-text-field v-model="account" :placeholder="$t('login.phone_hint')" color="success">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend">
						fa fa-phone
					</v-icon>
				</v-text-field>
				<v-text-field :placeholder="$t('setting.newLoginPwd_hint')" color="success" v-model="newPwd" type="password">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-lock
					</v-icon>
				</v-text-field>
				<v-text-field :placeholder="$t('setting.newLoginPwdConfirm_hint')" color="success" v-model="newPwdConfirm" type="password">
					<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
						fa fa-lock
					</v-icon>
				</v-text-field>
				<div class="row-content">
					<v-text-field v-model="code" :placeholder="$t('login.tac_hint')" color="success">
						<v-icon style="width: 20px; margin-right: 10px; color:#FF0000;" slot="prepend" >
							fa fa-envelope-open-o
						</v-icon>
					</v-text-field>
					<mt-button @click="sendCode" type="danger" plain style="height: 30px; border-radius: 15px; font-size: 10px;">{{$t('login.getTac')}}</mt-button>
				</div>
			</div>
			
			<div class="btn-content" style="margin-bottom: 0px;">
				<v-btn rounded block @click="submitForget" style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
			<router-link to="/" style="margin-top: 20px; font-size: 14px; color: #009245;">
				{{$t('login.haveAccount')}}
			</router-link>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	
	export default {
		name: 'ForgetPwd',
		data:() => ({
			forgetBg : {
				height: "100vh",
				backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			account: '',
			code: '',
			newPwd: '',
			newPwdConfirm: ''
		}),
		methods: {
			sendCode() {
				
				let phone = this.$data.account;
				if(null == phone || '' == phone) {
					Toast({
						message: this.$i18n.t("login.phone_hint"),
						position: 'bottom'
					});
					return;
				}
				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let params = {
					phone: phone
				}
				this.$request.sendCode(
					params
				).then(()=>{
					Indicator.close();
				});
			},
			
			submitForget() {
				
				let account = this.$data.account;
				let code = this.$data.code;
				let pwd = this.$data.newPwd;
				let confirmPwd = this.$data.newPwdConfirm;
				
				if(null == account || '' == account) {
					Toast({
						message: this.$i18n.t("login.phone_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("setting.newLoginPwd_hint"),
						position: 'bottom'
					});
					return;
				}
				if(null == confirmPwd || '' == confirmPwd) {
					Toast({
						message: this.$i18n.t("setting.newLoginPwdConfirm_hint"),
						position: 'bottom'
					});
					return;
				}
				if(pwd != confirmPwd) {
					Toast({
						message: this.$i18n.t("login.pwdConfirm_error"),
						position: 'bottom'
					});
					return;
				}
				if(null == code || '' == code) {
					Toast({
						message: this.$i18n.t("login.tac_hint"),
						position: 'bottom'
					});
					return;
				}
				
				this.requestForgetPwd();
			},
			requestForgetPwd() {
				let account = this.$data.account;
				let code = this.$data.code;
				let pwd = this.$data.newPwd;
				
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				let params = {
					account: account,
					code: code,
					newPassword: pwd,
					phone: account
				}
				this.$request.forget(
					params
				).then(()=>{
					Indicator.close();
					// 跳转登录
					this.$router.push({
						name: 'Login'
					})
				});
			}
		}
	}	
</script>

<style>
</style>
